export const INITIALISE = 'INITIALISE';
export const SET_IS_DRAGGING = 'SET_IS_DRAGGING';
export const SET_OPTIONS_PANEL_OPEN = 'SET_OPTIONS_PANEL_OPEN';
export const SET_OPTIONS_PANEL_CLOSED = 'SET_OPTIONS_PANEL_CLOSED';
export const SET_SINGLESELECT_OPTIONS = 'SET_SINGLESELECT_OPTIONS';
export const SET_MULTISELECT_OPTIONS = 'SET_MULTISELECT_OPTIONS';
export const SET_OPTIONS_PANEL_CLOSED_NO_SELECTION = 'SET_OPTIONS_PANEL_CLOSED_NO_SELECTION';
export const SET_OPTIONS_PANEL_CLOSED_ONBLUR = 'SET_OPTIONS_PANEL_CLOSED_ONBLUR';
export const SET_NEXT_SELECTED_INDEX = 'SET_NEXT_SELECTED_INDEX';
export const SET_NEXT_SELECTED_INDEX_ALPHA_NUMERIC = 'SET_NEXT_SELECTED_INDEX_ALPHA_NUMERIC';
